<template>
  <div class="tabHistory">
    <div
      class="tabHistory__item"
      :class="{
        'tabHistory__item--active': !isWithdraw
      }"
      @click="$router.replace('/tai-khoan/lich-su-nap-tien/')"
    >
      nạp tiền
    </div>
    <div
      class="tabHistory__item"
      :class="{
        'tabHistory__item--active': isWithdraw
      }"
      @click="$router.replace('/tai-khoan/lich-su-giao-dich/')"
    >
      rút tiền
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isWithdraw: {
      default: false,
      type: Boolean
    }
  }
};
</script>

<style lang="scss" scoped>
.tabHistory {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  justify-content: space-around;
  &__item {
    font-size: 0.9375rem;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;

    color: #666666;
    min-width: 95px;
    padding-bottom: 5px;
    cursor: pointer;

    &--active {
      color: #0d234a;
      border-bottom: 2px solid #0d234a;
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    justify-content: flex-start;
  }
}
</style>
