<template>
  <div class="widthdrawHistory">
    <div class="section-header d-none d-lg-flex">
      <div class="header-title">Lịch sử giao dịch</div>
      <!-- <div class="header-link">
        <router-link to="/tai-khoan/rut-tien/"
          ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
            <g transform="translate(-1095 -280)">
              <rect width="12" height="12" transform="translate(1095 280)" fill="#fff" opacity="0" />
              <path
                d="M.152-4.632a.45.45,0,0,0,0,.637l5.5,5.549a.45.45,0,0,0,.637,0L7.036.812a.45.45,0,0,0,0-.637L2.593-4.313,7.036-8.8a.45.45,0,0,0,0-.637l-.742-.742a.45.45,0,0,0-.637,0Z"
                transform="translate(1096.98 290.313)"
                fill="#666"
              />
            </g>
          </svg>
          Rút tiền</router-link
        >
      </div> -->
    </div>
    <hr class="d-none d-lg-block" />
    <TabHistory class="pb-3 pt-0 pt-lg-3" :isWithdraw="true" />
    <spinner v-if="widthdrawList.length == 0 && isLoading" />
    <div class="content-list widthdrawHistoryBody" v-else-if="widthdrawList.length > 0">
      <div
        class="content-block"
        v-for="(withdraw, index) in widthdrawList"
        :key="index"
        @click="showWidthdrawHistoryDetail(withdraw.id)"
      >
        <div class="deposit-detail">
          <div class="widthdrawHistoryMethod">{{ withdraw.title }}</div>
          <div class="deposit-date">{{ getOnlyDate(withdraw.date) }} {{ getOnlyTime(withdraw.date) }}</div>
        </div>
        <div class="deposit-status">
          <div class="widthdrawHistoryPrice success-status" v-if="withdraw.id && withdraw.return_type == 2">
            {{ formatPrice(withdraw.value) }}
          </div>
          <div class="widthdrawHistoryPrice colorRed" v-else-if="withdraw.id && withdraw.id.startsWith('R')">
            -{{ formatPrice(withdraw.value) }}
          </div>
          <div
            class="widthdrawHistoryPrice success-status"
            v-else-if="(withdraw.id && withdraw.id.startsWith('W')) || withdraw.id == null"
          >
            +{{ formatPrice(withdraw.value) }}
          </div>
          <div v-if="withdraw.return_type == 2 && withdraw.status == 1" class="widthdrawHistoryStatus">Đã nhận vé</div>
          <div v-else-if="withdraw.status == 1" class="widthdrawHistoryStatus">Hoàn tất</div>
          <div v-else-if="withdraw.status == 0" class="widthdrawHistoryStatus">Đang xử lý</div>
          <div v-else-if="withdraw.status == 2" class="widthdrawHistoryStatus">Đã huỷ</div>
        </div>
      </div>
      <spinner v-if="!isLoadEnd" class="d-block d-lg-none" />
      <!-- modal Withdraw History Detail -->
      <modal-withdraw-history-detail
        @setmodalWithdrawHistoryDetail="setmodalWithdrawHistoryDetail"
        :modalWithdrawHistoryDetail="modalWithdrawHistoryDetail"
      />
      <a class="d-none d-lg-block" @click="scrollBehavior()">
        <b-pagination
          v-if="totalPages > perPage"
          v-model="currentPage"
          :per-page="perPage"
          pills
          hide-ellipsis
          first-number
          last-number
          hide-goto-end-buttons
          :total-rows="totalPages"
          @input="getWithdrawHistoryPaging()"
          next-class="d-none"
          prev-class="d-none"
        ></b-pagination>
      </a>
    </div>
    <div v-else>Không có lịch sử giao dịch</div>
  </div>
</template>
<script>
import PaymentService from '@/api/payment';
import Spinner from '@/components/Spinner.vue';
import ModalWithdrawHistoryDetail from '../Widthdraw/ModalWithdrawHistoryDetail';
import TabHistory from '../TabHistory';
export default {
  layouts: 'WidthdrawHistory',
  components: { Spinner, ModalWithdrawHistoryDetail, TabHistory },
  data() {
    return {
      currentPage: 1,
      totalPages: 100,
      perPage: 10,
      widthdrawList: [],
      isLoading: true,
      modalWithdrawHistoryDetail: false,
      timeOut: null,
      resizeTimeout: null,
      mobileFlag: false,
      isLoadEnd: false,
      lastWidth: undefined
    };
  },
  mounted() {
    const isMobile = window.innerWidth <= this.getMobileThresshold();
    if (isMobile) {
      this.getWithdrawHistory(true);
    } else {
      this.getWithdrawHistoryPaging(1);
    }
  },
  created() {
    this.lastWidth = screen.width;
    window.addEventListener('scroll', this.handlerScroll);
    window.onresize = this.handleResize;

    if (this.$route.query && this.$route.query.request_id) {
      this.showWidthdrawHistoryDetail(this.$route.query.request_id);
    }
  },

  watch: {
    $route(to, from) {
      if (JSON.stringify(to.query) != JSON.stringify(from.query)) {
        if (to.query) {
          if (to.query.request_id) {
            this.showWidthdrawHistoryDetail(to.query.request_id);
          }
        }
      }
    }
  },
  computed: {},
  beforeDestroy() {
    window.removeEventListener('scroll', this.handlerScroll);
  },
  methods: {
    handlerScroll() {
      let bottomOfWindow =
        window.scrollY + window.innerHeight + 100 >= document.documentElement.scrollHeight &&
        window.innerWidth <= this.getMobileThresshold();
      if (bottomOfWindow) {
        this.loadMore();
      }
    },
    handleResize() {
      if (this.lastWidth === window.innerWidth) {
        return;
      }
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (this.mobileFlag === isMobile) {
        return;
      }
      this.mobileFlag = isMobile;

      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.currentPage = 1;
        this.isLoadEnd = false;
        this.depositList = [];
        if (isMobile) {
          this.getWithdrawHistory();
        } else {
          this.getWithdrawHistoryPaging();
        }
      }, 1000);
    },
    loadMore() {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
      }
      this.isLoading = true;
      this.timeOut = setTimeout(() => {
        this.getWithdrawHistory();
        this.isLoading = true;
      }, 200);
    },

    getWithdrawHistory(isFirst) {
      if (this.isLoadEnd) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      PaymentService.getWithdrawHistory(
        this.$store.getters.getterAccountId,
        this.perPage,
        isFirst ? 1 : this.currentPage + 1
      )
        .then((resp) => {
          if (resp.data.status == true) {
            this.totalPages = parseInt(resp.data.data.total);
            // if (resp.data.data.withdraws.length === 0) {
            //   this.isLoadEnd = true;
            //   this.isLoading = false;
            //   return;
            // }

            if (
              resp.data.data.withdraws.length + this.perPage * this.currentPage >= parseInt(resp.data.data.total) ||
              resp.data.data.withdraws.length === parseInt(resp.data.data.total)
            ) {
              this.isLoadEnd = true;
            }
            if (!isFirst) this.currentPage++;

            this.widthdrawList = this.widthdrawList.concat(resp.data.data.withdraws);
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getWithdrawHistoryPaging() {
      const isMobile = window.innerWidth <= this.getMobileThresshold();
      if (isMobile) {
        this.isLoading = false;

        return;
      }

      this.isLoading = true;
      if (!this.currentPage) {
        this.currentPage++;
      }
      PaymentService.getWithdrawHistory(this.$store.getters.getterAccountId, this.perPage, this.currentPage)
        .then((resp) => {
          if (resp.data.status == true) {
            this.totalPages = parseInt(resp.data.data.total);

            this.widthdrawList = resp.data.data.withdraws;
          }
          this.isLoading = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    showWidthdrawHistoryDetail(id) {
      if (id) {
        PaymentService.getWithdrawHistoryDetail(this.$store.getters.getterAccountId, id)
          .then((resp) => {
            const data = resp.data;
            if (data && data.status) {
              this.$store.commit('setWithdrawHistoryDetail', data.data);
              this.modalWithdrawHistoryDetail = true;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        this.$store.commit('setWithdrawHistoryDetail', {});
      }
    },
    setmodalWithdrawHistoryDetail(newVal) {
      this.modalWithdrawHistoryDetail = newVal;
    },
    scrollBehavior() {
      document.getElementById('scroll').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style lang="scss">
.widthdrawHistory {
  .widthdrawHistoryBody {
    .colorRed {
      color: #e20c0c;
    }
    .colorBlue {
      color: #2a9d8f;
    }
    .widthdrawHistoryMethod {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: bold;
      color: #0d234a;
    }
    .widthdrawHistoryPrice {
      font-size: 20px;
      font-weight: 600;
    }
    .widthdrawHistoryStatus {
      font-size: 15px;
      font-weight: normal;
      color: #333333;
      text-align: right;
    }
  }
}
@media (max-width: map-get($grid-breakpoints, 'sm')) {
  .widthdrawHistory {
    .widthdrawHistoryBody {
      .widthdrawHistoryMethod {
        margin-bottom: 7px;
        font-size: 15px;
        font-weight: 600;
        color: #0d234a;
      }
      .widthdrawHistoryPrice {
        font-size: 18px;
        font-weight: 600;
      }
      .widthdrawHistoryStatus {
        font-size: 15px;
        font-weight: normal;
        color: #333333;
        text-align: right;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'md')) {
  .widthdrawHistory {
    .widthdrawHistoryBody {
      .widthdrawHistoryMethod {
        margin-bottom: 7px;
        font-size: 15px;
        font-weight: 600;
        color: #0d234a;
      }
      .widthdrawHistoryPrice {
        font-size: 18px;
        font-weight: 600;
      }
      .widthdrawHistoryStatus {
        font-size: 15px;
        font-weight: normal;
        color: #333333;
        text-align: right;
      }
    }
  }
}
</style>
