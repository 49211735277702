<template>
  <b-modal
    headerClass="border-bottom-0"
    hide-footer
    modal-class="modalWithdrawHisDetail modalRegisterRep"
    :content-class="{
      pt40: isPlayTrialModeMobile
    }"
    @hide="onCloseModal"
    v-model="modalWithdrawHistoryDetailData"
    centered
  >
    <template v-slot:modal-title>
      <p class="d-block pointer font-20 ">Chi tiết giao dịch #{{ getWithdrawHistoryDetail.id }}</p>
    </template>

    <div class="d-flex flex-column justify-content-between w-100 modalWithdrawHisDetailBody">
      <div class="modalWithdrawHisDetailContent">
        <p class="text-uppercase contentTitle">hình thức</p>
        <p v-if="isRewardTransaction" class="contentValue">Trả thưởng vé {{ getWithdrawHistoryDetail.title }}</p>
        <p v-if="getWithdrawHistoryDetail.title === 'wallet'" class="contentValue">Nạp tiền tài khoản mua vé</p>
        <p v-if="getWithdrawHistoryDetail.title === 'bank'" class="contentValue">Chuyển khoản ngân hàng</p>
      </div>
      <div class="modalWithdrawHisDetailContent row">
        <div class="col-lg-6 col-6">
          <p class="text-uppercase contentTitle">
            {{ ['wallet', 'bank'].includes(getWithdrawHistoryDetail.title) ? 'Rút tiền' : 'Số tiền' }}
          </p>
          <p class="contentValue">{{ formatPrice(getWithdrawHistoryDetail.amount) }}</p>
        </div>
        <div class="col-lg-6 col-6 contentTitle">
          <p class="text-uppercase">
            Phí giao dịch
          </p>
          <p
            v-if="getWithdrawHistoryDetail.title === 'bank' && !getWithdrawHistoryDetail.withdraw_fee"
            class="contentValue"
          >
            {{ formatPrice(Math.ceil((getWithdrawHistoryDetail.amount * transactionFee.bank) / 100)) }}
          </p>
          <p v-else class="contentValue">
            {{ formatPrice(getWithdrawHistoryDetail.withdraw_fee || 0) }}
          </p>
        </div>
      </div>
      <div class="modalWithdrawHisDetailContent row">
        <div class="col-lg-6 col-6 contentTitle">
          <p class="text-uppercase">thực lĩnh</p>
          <p
            v-if="getWithdrawHistoryDetail.title === 'bank' && !getWithdrawHistoryDetail.actual_amount"
            class="contentValue"
          >
            {{
              formatPrice(
                getWithdrawHistoryDetail.amount -
                  Math.ceil((getWithdrawHistoryDetail.amount * transactionFee.bank) / 100)
              )
            }}
          </p>
          <p v-else class="contentValue">
            {{
              formatPrice(
                getWithdrawHistoryDetail.actual_reward ||
                  getWithdrawHistoryDetail.actual_amount ||
                  getWithdrawHistoryDetail.amount
              )
            }}
          </p>
        </div>
      </div>
      <div class="modalWithdrawHisDetailContent row">
        <div class="col-lg-6 col-6">
          <p class="text-uppercase contentTitle">thời gian</p>
          <p class="contentValue">
            {{ getOnlyDate(getWithdrawHistoryDetail.date) }} {{ getOnlyTime(getWithdrawHistoryDetail.date) }}
          </p>
        </div>
        <div class="col-lg-6 col-6">
          <p class="text-uppercase contentTitle">trạng thái</p>
          <p
            class="contentValue"
            v-if="getWithdrawHistoryDetail.return_type == 2 && getWithdrawHistoryDetail.status == 1"
          >
            Đã nhận vé
          </p>
          <p class="contentValue" v-else-if="getWithdrawHistoryDetail.status == 1">Hoàn tất</p>
          <p class="contentValue danger-status" v-else-if="getWithdrawHistoryDetail.status == 2">Đã hủy</p>
          <p class="contentValue warning-status" v-else>Đang xử lý</p>
        </div>
      </div>
      <div v-if="getWithdrawHistoryDetail.title === 'bank'">
        <div class="modalWithdrawHisDetailContent">
          <p class="text-uppercase contentTitle">ngân hàng</p>
          <p class="contentValue">{{ getWithdrawHistoryDetail.bank_name }}</p>
        </div>
        <div class="modalWithdrawHisDetailContent row">
          <div class="col-lg-6 col-6">
            <p class="text-uppercase contentTitle">Số tài khoản</p>
            <p class="contentValue">{{ getWithdrawHistoryDetail.bank_account_number }}</p>
          </div>
          <div class="col-lg-6 col-6">
            <p class="text-uppercase contentTitle">Chủ tài khoản</p>
            <p class="contentValue">{{ getWithdrawHistoryDetail.bank_account_name }}</p>
          </div>
        </div>
      </div>

      <div class="modalWithdrawHisDetailContent">
        <p class="text-uppercase contentTitle">số điện thoại</p>
        <p class="contentValue">
          <CensorData :data="getWithdrawHistoryDetail.phone" />
        </p>
      </div>
      <div
        v-if="getWithdrawHistoryDetail.title == 'bank' && [0, 2].includes(getWithdrawHistoryDetail.status)"
        class="modalWithdrawHisDetailNote"
      >
        <p class="text-uppercase contentTitle">ghi chú</p>
        <p class="contentValue">{{ getWithdrawHistoryDetail.note }}</p>
      </div>
      <button v-if="isRewardTransaction" class="btn btnPrimary btnPrimary--contained w-100" @click="handleOpenDetail">
        xem vé trúng thưởng
      </button>
    </div>
  </b-modal>
</template>

<script>
import CensorData from '@/components/CensorData';

export default {
  components: { CensorData },
  props: {
    modalWithdrawHistoryDetail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: true,
      transactionFee: {
        bank: 2,
        wallet: 0
      }
    };
  },
  computed: {
    modalWithdrawHistoryDetailData: {
      get() {
        return this.modalWithdrawHistoryDetail;
      },
      set(newVal) {
        this.$emit('setmodalWithdrawHistoryDetail', newVal);
      }
    },
    getWithdrawHistoryDetail() {
      return this.$store.getters.getterWithdrawHistoryDetail;
    },
    isRewardTransaction() {
      return ['Keno', 'Power', 'Mega', 'Max3D', 'Max3D+', 'Max4D', 'Max3DPro'].includes(
        this.getWithdrawHistoryDetail.title
      );
    }
  },
  methods: {
    onCloseModal() {},
    handleOpenDetail() {
      if (this.getWithdrawHistoryDetail?.order_type === 'group') {
        this.$router.push(`/tai-khoan/ket-qua-mua-chung/${this.getWithdrawHistoryDetail.bulk_info?.group_id}`);
      } else {
        this.$router.push(`/tai-khoan/ket-qua/${this.getWithdrawHistoryDetail.display_ticket_id}`);
      }
    }
  },
  watch: {
    modalWithdrawHistoryDetailData: function(newVal) {
      this.handleToggleModal(newVal);
    }
  }
};
</script>

<style lang="scss">
.modalWithdrawHisDetail {
  .modal-header {
    color: #0d234a;
  }
  .modal-body {
    padding: 10px 16px 18px 15px;
    .modalWithdrawHisDetailBody {
      .modalWithdrawHisDetailContent {
        margin-bottom: 19px;
      }
      .contentTitle {
        font-size: 14px;
        color: #666666;
      }
      .contentValue {
        font-size: 15px;
        font-weight: 600;
        color: #333333;
      }
      .modalWithdrawHisDetailNote {
        background-color: #f2f2f2;
        padding: 10px 32px 15px 11px;
      }
    }
  }
}
</style>
